<template>
  <div class="container mx-auto">
    <app-loading-page
      :text="$t('general.loading')"
      :loading="submitLoading"
      class="w-40 h-20 m-auto rounded-md shadow-md bg-transparent"
    />

    <app-modal-payout-detail
      :show="showModalPayoutDetail"
      @close="showModalPayoutDetail = false"
      :payout="selectPayout"
      processedPaymentLinkRoute="account.payout.transaction.payment-link"
      @update:payout="selectPayout = $event"
    />

    <!-- Download CSV Modal -->
    <app-modal
      :show="show_modal_download_csv"
      @close="show_modal_download_csv = false"
    >
      <template v-slot:title>
        {{ $t("payout.modal.download_csv_title") }}
      </template>
      <template v-slot:body>
        <p class="text-sm text-gray-500">
          {{ $t("payout.modal.download_csv_description") }}
        </p>
      </template>
      <template v-slot:action>
        <app-button
          @click="downloadCsvListPayout"
          :showf70Icon="false"
          type="button"
          class="sm:ml-3 sm:w-auto"
        >
          {{ $t("payout.modal.download_csv_button") }}
        </app-button>
      </template>
    </app-modal>

    <!-- Update Status Modal -->
    <app-modal
      padding="py-3 px-6"
      paddingBody="py-3"
      @close="closeModalUpdateStatus"
      :show="show_modal_update_status"
    >
      <template v-slot:title>
        <p class="capitalize">{{ $t("payout.actions") }}</p>
      </template>
      <template v-slot:body>
        <div class="divide-y space-y-5">
          <!-- Select Status -->
          <div class="flex items-center space-x-3 my-3 px-6">
            <p class="flex-none text-sm mt-2 font-medium antialiased">
              {{ $t("payout.update_status") }}
            </p>
            <app-form-select
              v-model="select_status"
              :allowFilter="false"
              :datas="payout_statuses"
              placeholder="Please select status"
              class="flex-1"
            />
          </div>

          <!-- Status Transfer -->
          <div
            v-if="select_status?.id == PAYOUT_STATUS.APPROVED"
            class="divide-y space-y-5"
          >
            <!-- Form Reference ID -->
            <app-form-input
              type="text"
              class="px-6 pt-3"
              v-model="payout.reference_id"
              :errorMessage="errors.reference_id"
              :placeholder="$t('payout.modal.insert_ref_id_placeholder')"
            />
          </div>

          <!-- Status Pending -->
          <div
            v-else-if="select_status?.id == PAYOUT_STATUS.PENDING"
            class="space-y-2 px-6"
          >
            <app-form-textarea
              type="text"
              class="pt-3"
              labelText="Remark"
              v-model="payout.reason"
              :errorMessage="errors.reason"
              :disabled="!edit_status && current_tab == PAYOUT_STATUS.PENDING"
            />
            <div
              v-if="current_tab == PAYOUT_STATUS.PENDING"
              class="flex w-full justify-end"
            >
              <app-button-outline @click="edit_status = !edit_status">
                {{ $t("payout.modal.edit_issue") }}
              </app-button-outline>
            </div>
          </div>

          <!-- Upload Document -->
          <div v-if="select_status?.id == PAYOUT_STATUS.PENDING || select_status?.id == PAYOUT_STATUS.APPROVED">
            <!-- Loading  -->
            <div v-if="loadingDocs" class="w-full flex justify-center py-8">
              <app-icon-loading iconColor="primary" />
            </div>

            <!-- Show Document File -->
            <div v-else-if="documentFile.name" class="px-6 py-3">
              <app-alert-error
                v-if="errors['document_path']"
                :message="errors['document_path'][0]"
                class="bg-red-100 p-3 rounded-md mb-3"
              />
              <div class="flex justify-between p-2 border rounded-md">
                <a
                  :href="documentFile.url ?? '#'"
                  target="_blank"
                  class="text-gray-500 text-sm"
                >
                  {{ documentFile.name }}
                </a>
                <button @click="clearFile">
                  <app-icon name="XIcon" class="w-4 text-gray-400 text-bold" />
                </button>
              </div>
            </div>

            <!-- Upload Document -->
            <div v-else class="px-6 py-3">
              <app-alert-error
                v-if="errors['document_path']"
                :message="errors['document_path'][0]"
                class="bg-red-100 p-3 rounded-md"
              />

              <app-alert-error
                v-if="mediaErrors['file']"
                :message="mediaErrors['file'][0]"
                class="bg-red-100 p-3 rounded-md"
              />

              <app-button-outline
                v-if="!documentFile.name"
                class="relative border-dashed border-gray-300 w-full py-4 mt-3"
                :class="{
                  'border-red-500':
                    errors['document_path'] || mediaErrors['file'],
                }"
                @click="show_modal_update_status = true"
              >
                <span class="flex flex-col items-center gap-2">
                  <app-icon name="PlusSmIcon" class="w-5" />
                  <p class="text-sm antialiased">
                    {{ $t("payout.modal.upload_proof_documents") }}
                  </p>
                  <p class="text-xs antialiased">
                    {{ $t("payout.upload_info") }}
                  </p>
                </span>

                <input
                  type="file"
                  class="cursor-pointer w-full h-full absolute block opacity-0 pin-r pin-t"
                  @change="onFileChange"
                />
              </app-button-outline>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:action>
        <app-button
          width=""
          class="ml-2"
          @click="updateStatus()"
          :showf70Icon="false"
        >
          {{ $t("payout.update_status") }}
        </app-button>
      </template>
    </app-modal>

    <!-- Proceed Settlements Modal -->
    <app-modal
      :show="show_modal_proceed_settlements"
      @close="show_modal_proceed_settlements = false"
    >
      <template v-slot:title>{{
        $t("payout.modal.proceed_to_settlement_title")
      }}</template>
      <template v-slot:body>
        <p class="text-sm text-gray-500">
          {{ $t("payout.modal.proceed_settlement_description") }}
        </p>
      </template>
      <template v-slot:action>
        <app-button
          @onClick="bulkUpdatePayoutStatusSettlements"
          @click="show_modal_proceed_settlements = false"
          :showf70Icon="false"
          type="button"
          class="sm:ml-3 sm:w-auto"
        >
          {{ $t("payout.modal.proceed_to_settlement_button") }}
        </app-button>
      </template>
    </app-modal>

    <div class="mx-4 my-5 flex flex-row justify-between items-center">
      <span class="text-xl ml-3">{{ $t("payout.payment_list") }}</span>
      <app-button
        width="w-20 lg:w-1/6"
        v-if="isStatusRequested"
        :disabled="!select_table_request.length"
        :showf70Icon="false"
        @click="showModalSettlements"
      >
        {{ $t("payout.modal.proceed_to_settlement_button") }}
      </app-button>
    </div>
    <hr class="order-b border-gray-200 mx-3" />
    <div class="container mx-auto">
      <app-table
        :tabs="tabs"
        :right_tabs="right_tabs"
        :apiResponse="list_payout"
        :showEmptyButton="false"
        @params-changed="params_changed"
        @tab-changed="tab_changed"
        @select-table-changed="select_table_changed"
        :selectable="isStatusRequested"
        :loading="loading && !indexClicked"
        :filters="[
          {
            key: 'filter[bank_id]',
            type: 'select',
            placeholder: $t('payout.table.account.header_bank_name'),
            items: banks,
          },
          {
            key: 'filter[account_no]',
            type: 'text',
            placeholder: $t('payout.table.account.header_acc_no'),
          },
          {
            key: 'filter[account_holder]',
            type: 'text',
            placeholder: $t('payout.table.account.header_acc_holder'),
          },
          {
            key: 'filter[amount]',
            type: 'number',
            placeholder: $t('payout.table.account.header_amount'),
          },
          {
            key: 'filter[business_name]',
            type: 'text',
            placeholder: $t('payout.table.account.header_business_name'),
          },
          {
              key: 'filter[currency]',
              type: 'select',
              placeholder: $t('payment.link.filter.currency'),
              value: {},
              items: [
                {
                  id: 'MYR',
                  name: 'MYR',
                },
                {
                  id: 'SGD',
                  name: 'SGD',
                },
                {
                  id: 'BAHT',
                  name: 'BAHT',
                },
                {
                  id: 'USD',
                  name: 'USD',
                },
                {
                  id: 'IDR',
                  name: 'IDR',
                },
              ],
            },
          {
            key: 'filter[created_at_between]',
            type: 'date',
            placeholder: 'Created At',
            value: '',
          },
        ]"
        @on-item-click="onItemClick"
      >
        <template v-slot:button>
          <app-button
            @click="show_modal_download_csv = true"
            :showf70Icon="false"
            type="button"
            class="sm:ml-3 sm:w-auto"
          >
            {{ $t("payout.modal.download_csv_button") }}
          </app-button>
        </template>
        <template v-slot:header>
          <td>
            <b>{{ LabelHeaderDate }}</b>
          </td>
          <td>
            <b>{{ $t("payout.table.account.header_bank_name") }}</b>
          </td>
          <td>
            <b>{{ $t("payout.table.account.header_acc_no") }}</b>
          </td>
          <td>
            <b>{{ $t("payout.table.account.header_acc_holder") }}</b>
          </td>
          <td>
            <b>{{ $t("payout.table.account.header_amount") }}</b>
          </td>
          <td>
            <b>{{ $t("payout.table.account.header_business_name") }}</b>
          </td>
          <td v-if="isStatusApproved">
            <b>{{ $t("payout.table.account.header_status") }}</b>
          </td>
          <td v-if="isStatusPending">
            <b>{{ $t("payout.table.account.header_remark") }}</b>
          </td>
          <td v-if="isStatusProcessing">
            <b>{{ $t("payout.table.account.header_action") }}</b>
          </td>
          <td v-else></td>
        </template>
        <template v-slot:body="data" >
          <td>
            <p>
              {{
                $moment( data.model?.updated_at
                ).format("DD MMM YYYY hh:mm a")
              }}
            </p>
          </td>
          <td>
            <p>
              {{ data.model?.business?.bank?.bank.display_name }}
            </p>
          </td>
          <td>
            <p>{{ data.model?.business?.bank?.account_no ?? "-" }}</p>
          </td>
          <td>
            <p>{{ data.model?.business?.bank?.account_holder ?? "-" }}</p>
          </td>
          <td>
            <div class="flex space-x-1 items-center">
                <p>
                  {{
                    $formats.currency(
                      data.model?.currency,
                      data.model?.amount ?? 0.0
                    )
                  }}
                </p>
            </div>
          </td>
          <td>
            <p>
              {{ data.model?.business?.name }}
            </p>
          </td>
          <td v-if="isStatusPending">
            <p v-for="(history, index) in data.model?.histories" :key="index">
              <span v-if="index === data.model.histories.length - 1">
                {{ history.reason ?? "-" }}
              </span>
            </p>
          </td>
          <td @click.stop v-if="isStatusProcessing">
            <app-button-outline
              class="border-dashed"
              @click="showModalUpdateStatus(data.model)"
            >
              <span class="flex flex-row items-center gap-2">
                <app-icon name="PlusSmIcon" class="w-5" />
                <p>{{ $t("payout.update_status") }}</p>
              </span>
            </app-button-outline>
          </td>
          <td v-if="isStatusApproved">
            <div
              class="p-1 flex text-xs rounded-md space-x-1 justify-center text-green-900 bg-success-badge border border-green-600"
            >
              <app-icon name="CheckIcon" class="w-4"></app-icon>
              <p>{{ $t("payout.transferred_button") }}</p>
            </div>
          </td>
          <td v-else>&nbsp;</td>
          <td v-if="isStatusRequested || isStatusReturn || isStatusApproved">&nbsp;</td>
        </template>
        <template v-slot:footer>
          <tr>
            <td
              class="font-bold"
              :colspan="isStatusRequested ? 6 : 5 "
            >
              {{ $t("wallet.transaction.total") }}
            </td>
            <td v-if="list_payout?.summary?.amount" class="font-bold">
              {{
                $formats.currency(
                  list_payout?.summary?.currency,
                  list_payout?.summary?.amount ?? 0
                )
              }}
            </td>
            <td v-else class="font-bold">
              {{ list_payout?.summary?.currency + " 0.00 " }}
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
        </template>
      </app-table>
    </div>
  </div>
</template>

<script>
import PAYOUT_STATUS from "@/utils/const/payout_status";

export default {
  data() {
    return {
      PAYOUT_STATUS: PAYOUT_STATUS,
      show_modal_download_csv: false,
      show_modal_update_status: false,
      show_modal_proceed_settlements: false,
      tabs: [],
      right_tabs: [],
      current_tab: null,
      queryParams: "",
      select_table_request: [],
      submitLoading: false,
      documentFile: [],
      documentPath: null,
      loadingDocs: false,
      indexClicked: null,
      showModalPayoutDetail: false,
      selectPayout: null,
      payout_statuses: [],
      select_status: null,
      submit_status: null,
      edit_status: false,
    };
  },
  watch: {
    select_status(val) {
      this.clearError();
    },
  },
  created() {
    this.fetchListPayout();
  },
  computed: {
    list_payout() {
      return this.$store.getters["walletStore/list_payout"];
    },
    payout() {
      return this.$store.getters["walletStore/payout"];
    },
    loading() {
      return this.$store.getters["walletStore/loading"];
    },
    errors() {
      return this.$store.getters["walletStore/errors"];
    },
    mediaErrors() {
      return this.$store.getters["mediaStore/errors"];
    },
    banks() {
      return this.$store.getters["referenceStore/banks"];
    },
    isStatusRequested() {
      return [PAYOUT_STATUS.REQUESTED].includes(this.current_tab);
    },
    isStatusPending() {
      return [PAYOUT_STATUS.PENDING].includes(this.current_tab);
    },
    isStatusProcessing() {
      return [PAYOUT_STATUS.PROCESSING].includes(this.current_tab);
    },
    isStatusApproved() {
      return [PAYOUT_STATUS.APPROVED].includes(this.current_tab);
    },
    isStatusReturn() {
      return [PAYOUT_STATUS.RETURNED].includes(this.current_tab);
    },
    isStatusRejected() {
      return [PAYOUT_STATUS.REJECTED].includes(this.current_tab);
    },
    hasDocumentFile() {
      return this.documentFile !== "";
    },
    LabelHeaderDate() {
      switch (this.current_tab) {
        case PAYOUT_STATUS.PROCESSING:
          return this.$t("payout.table.account.header_date_processed");
        case PAYOUT_STATUS.PENDING:
          return this.$t("payout.table.account.header_date_pending");
        case PAYOUT_STATUS.APPROVED:
          return this.$t("payout.table.account.header_date_success");
        case PAYOUT_STATUS.RETURNED:
          return this.$t("payout.table.account.header_date_returned");
        default:
          return this.$t("payout.table.account.header_date_requested"); //requested tab
      }
    },
  },
  mounted() {
    this.fetchListPayout();

    this.$store.dispatch("referenceStore/getBanks", 0);

    this.tabs = [
      {
        key: PAYOUT_STATUS.REQUESTED,
        title: this.$t("payout.table.account.tab_request"),
      },
      {
        key: PAYOUT_STATUS.PROCESSING,
        title: this.$t("payout.table.account.tab_settlement"),
      },
      {
        key: PAYOUT_STATUS.PENDING,
        title: this.$t("payout.table.account.tab_pending"),
      },
    ];

    this.right_tabs = [
      {
        key: PAYOUT_STATUS.RETURNED,
        title: this.$t("payout.table.account.tab_returned"),
      },
      {
        key: PAYOUT_STATUS.APPROVED,
        title: this.$t("payout.table.account.tab_approved"),
      },
    ];
  },
  methods: {
    closeModalUpdateStatus() {
      this.show_modal_update_status = false;

      this.payout.reference_id = null;
      this.payout.reason = null;

      this.select_status = null;
      this.submit_status = null;
      this.edit_status = false;

      this.clearFile();
    },
    select_table_changed(selected) {
      this.select_table_request = selected.map((obj) => {
        return obj._id;
      });
    },
    onFileChange(event) {
      this.clearFile();
      this.documentFile = event.target.files[0];
      this.loadingDocs = true;

      const form = new FormData();
      form.append("file", this.documentFile);

      this.$store
        .dispatch("mediaStore/uploadFile", {
          file: form,
        })
        .then((result) => {
          this.documentPath = result.data.media_path;
        })
        .catch((error) => {
          this.documentPath = null;
          this.documentFile = [];
        })
        .finally(() => {
          this.loadingDocs = false;
        });
    },
    clearFile() {
      this.documentPath = null;
      this.documentFile = [];
      this.clearError();
    },
    clearError() {
      this.$store.commit("walletStore/setErrorMessage");
      this.$store.commit("mediaStore/setErrorMessage");
    },
    params_changed(filters, queryParams) {
      this.queryParams = queryParams;
      this.fetchListPayout();
    },
    tab_changed(tab_name) {
      this.current_tab = tab_name;
    },
    async fetchListPayout() {
      this.getTabStatusTab();
      await this.$store.dispatch(
        "walletStore/getListPayouts",
        this.queryParams
      );
    },
    async downloadCsvListPayout() {
      this.show_modal_download_csv = false;
      this.submitLoading = true;
      const csv_url = await this.$store.dispatch(
        "walletStore/getListPayoutsExports",
        this.queryParams
      );
      this.submitLoading = false;
      window.location.href = csv_url.data;
      this.fetchListPayout();
    },
    getTabStatusTab() {
      let currentTab = this.current_tab ?? this.$route.query.tab;

      if (currentTab == null) {
        currentTab = PAYOUT_STATUS.REQUESTED;
      }

      this.current_tab = currentTab;
      this.queryParams =
        this.queryParams == ""
          ? "?filter[status]=" + this.current_tab
          : this.queryParams + "&filter[status]=" + this.current_tab;
    },

    showModalSettlements() {
      this.show_modal_proceed_settlements = true;
    },

    async updateRoundOffAmountPayout(payout) {
      this.indexClicked = payout._id;

      if (payout.round_off == null) {
        await this.$store.dispatch(
          "walletStore/updateRoundOffAmountPayout",
          payout._id
        );
      } else {
        await this.$store.dispatch(
          "walletStore/cancelRoundOffAmountPayout",
          payout._id
        );
      }

      await this.fetchListPayout();

      this.indexClicked = null;
    },

    showRoundOffAmountPayout(payout) {
      let amountString = payout.amount?.toString();
      let showTabs = [PAYOUT_STATUS.REQUESTED, PAYOUT_STATUS.PROCESSING];

      if (amountString == null || amountString?.length == 0) {
        return null;
      }

      if (!showTabs.includes(this.current_tab)) {
        return null;
      }

      return amountString[amountString.length - 1] != 0;
    },

    showModalUpdateStatus(payout) {
      this.clearFile();
      this.payout.reason = null;
      this.payout.reference_id = null;
      this.show_modal_update_status = true;

      this.payout_statuses = [
        {
          id: PAYOUT_STATUS.APPROVED,
          name: "Transferred",
        },
        {
          id: PAYOUT_STATUS.PENDING,
          name: "Pending action",
        },
      ];

      this.$store.commit("walletStore/setPayout", payout);
    },

    async bulkUpdatePayoutStatusSettlements() {
      this.submitLoading = true;
      this.show_modal_proceed_settlements = false;
      var payout_ids = Object.values(this.select_table_request);

      const payoutResult = await this.$store.dispatch(
        "walletStore/bulkUpdatePayoutStatus",
        {
          payout_ids: payout_ids,
        }
      );

      this.submitLoading = false;

      if (payoutResult != null) {
        this.submitLoading = false;
        this.select_table_request = [];
        this.fetchListPayout();
      }
    },

    async updateStatus() {
      this.clearError();

      let payload = {};

      if (this.select_status.id == PAYOUT_STATUS.APPROVED) {
        payload = {
          status: this.select_status.id,
          reference_id: this.payout.reference_id ?? null,
          document_path: this.documentPath,
        };
      }

      if (this.select_status.id == PAYOUT_STATUS.PENDING) {
        payload = {
          status: this.select_status.id,
          reason: this.payout.reason ?? null,
          document_path: this.documentPath,
        };

        if (payload.document_path == null) {
          delete payload.document_path;
        }
      }

      if (this.select_status.id == PAYOUT_STATUS.RETURNED) {
        payload = {
          status: this.select_status.id,
        };
      }

      this.submitLoading = true;      

      const payoutResult = await this.$store.dispatch(
        "walletStore/updatePayoutStatusWithDocuments",
        payload
      );

      this.submitLoading = false;

      if (this.$lodash.isEmpty(payoutResult.errors) &&  this.select_status.id == PAYOUT_STATUS.PENDING) {
        this.closeModalUpdateStatus();
        this.fetchListPayout();
        this.$notify(
          {
            group: "success",
            title: this.$t("payout.alert_pending_action"),
          },
          5000
        );
      } 
      
      if (this.$lodash.isEmpty(payoutResult.errors) &&  this.select_status.id == PAYOUT_STATUS.APPROVED){
        this.closeModalUpdateStatus();
        this.fetchListPayout();
        this.$notify(
          {
            group: "success",
            title: this.$t("payout.alert_transferred_title"),
          },
          5000
        );
      }
    },

    onItemClick(data) {
      this.showModalPayoutDetail = !this.showModalPayoutDetail;
      this.selectPayout = data;
    },
  },
};
</script>
